<template>
  <div>
    <!-- Table Container Card -->

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="4"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <router-link
                class="btn btn-primary"
                :to="{ name: 'AddJobEffort'}"
              >
                Add Effort
              </router-link>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refoperatorJobListTable"
        class="position-relative"
        :items="row"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(targetFinishDate)="data">
          {{ format_date(data.item.targetFinishDate) }}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="changeOperatorJob(data.item,1)">
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span>{{ $t("Edit") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeOperatorJob(data.item,2)">
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>{{ $t("Delete") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="comment(data.item)">
              <feather-icon
                icon="MessageSquareIcon"
                class="mr-50"
              />
              <span>{{ $t("Send Comment ") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totaloperatorJobs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BModal,
  VBModal, BButton,
  BForm, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormTextarea, BFormText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  inject, toRefs, watch, ref, onUnmounted, computed,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import useoperatorJobList from './useOperatorJobList'
import jobStoreModule from '../taskManagementStoreModule'
// import DateFilter from '../components/DateFilter.vue'

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // DateFilter,
    BForm,
    BTable,
    BFormGroup,
    BFormTextarea,
    BFormText,
  },
  data() {
    return {
      selectedOperatorJob: null,
      id: 0,
      search: '',
      actionType: 0,
      isCommentActive: false,
    }
  },
  provide() {
    const operatorJobdata = {}
    Object.defineProperty(operatorJobdata, 'selectedOperatorJob', {
      enumerable: true,
      get: () => this.selectedOperatorJob,
    })

    return {
      operatorJobdata,
    }
  },
  computed: {
  },
  watch: {
    datefilterId(value) {
      // this.GetoperatorJobDatas(this.workcenterID, value)
    },
  },

  methods: {
    changeOperatorJob(e, actionType) {
      this.actionType = actionType
      this.selectedOperatorJob = e
      this.isAddNewOperatorJobSidebarActive = true
    },
    newOperatorJob() {
      this.selectedOperatorJob = {
        Id: null,
        name: null,
        code: null,
        operatorJobTypeID: null,
        workcenterID: null,
        isActive: true,
      }

      this.actionType = 0
      this.isAddNewOperatorJobSidebarActive = true
    },
    comment(data) {
      this.operatorJobId = data.id
      this.$refs.refoperatorJobComment.show()
    },
    format_date(value) {
      if (value) {
        const myDate = new Date('0001-01-01T00:00:00Z')
        const dateItem = moment(value)

        if (dateItem > myDate) {
          return moment(value).locale('en').format('LLL')
        }
      }
    },
  },

  setup() {
    const PAMIS_MODULE_NAME = 'pamis-job'

    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME)) store.registerModule(PAMIS_MODULE_NAME, jobStoreModule)

    const isAddNewOperatorJobSidebarActive = ref(false)
    const operatorJobId = ref(0)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME)) store.unregisterModule(PAMIS_MODULE_NAME)
    })
    const {
      row,
      tableColumns,
      perPage,
      currentPage,
      totaloperatorJobs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refoperatorJobListTable,
      refetchData,
      search,
    } = useoperatorJobList()

    return {
      isAddNewOperatorJobSidebarActive,
      operatorJobId,
      row,
      tableColumns,
      perPage,
      currentPage,
      totaloperatorJobs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refoperatorJobListTable,
      refetchData,
      search,
      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
